<template>
  <v-responsive>
    <toolbar-core />
    <v-container>
      <v-row justify="center">
        <v-col col="12" sm="12" md="10">
          <p class="text-center text-regular">
            Somos tu <span class="text-title">mejor opción de formación </span>
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="8">
          <p class="text-center text-list-regular">
            Cápsulas de aprendizaje en tu móvil, invierte 5 minutos al día y
            aprende nuevos conocimientos, relevantes para tu vida y trabajo.
          </p>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click="$router.push({ name: 'Register' })"
              :color="hover ? '#E05492' : '#0CAAC8'"
              x-large
              rounded
              class="white--text text-lowercase"
              ><span class="text-capitalize white--text mr-2">Inicia</span>
              ya</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" sm="12" md="10">
          <v-card class="card-radius" flat color="#dbdbdb73">
            <div class="my-10 mx-10">
              <v-row>
                <v-col cols="12" class="my-10">
                  <p class="text-center text-regular">
                    Inicia en tan solo <span class="text-title">3 pasos</span>
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="order-1 order-sm-0 order-md-0 order-lg-0"
                >
                  <!-- <v-card class="mx-auto card-radius" width="400"> -->
                  <v-img
                    src="../../assets/img/Imagen 1.svg"
                    alt="imagen principal"
                    dark
                  >
                  </v-img>
                  <!-- </v-card> -->
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="d-flex order-0 order-sm-1 order-md-1 order-lg-1"
                >
                  <div class="my-auto">
                    <p class="text-sub-title">Categoría</p>
                    <span class="text-list-regular">
                      Elige el tipo de contenido con el que quieres aprender:
                      audios, videos o lecturas.
                    </span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="d-flex">
                  <div class="my-auto">
                    <p class="text-sub-title">Temática</p>
                    <span class="text-list-regular">
                      Elige el tema que deseas aprender entre transformación
                      Digital, Innovación, Marketing Digital, y Emprendimiento,
                      Desarrollo Personal y Liderazgo o Inglés.
                    </span>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <!-- <v-card class="mx-auto card-radius" width="500"> -->
                  <v-img
                    src="../../assets/img/Imagen 2.svg"
                    alt="imagen principal"
                    dark
                  >
                  </v-img>
                  <!-- </v-card> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="order-1 order-sm-0 order-md-0 order-lg-0"
                >
                  <!-- <v-card class="mx-auto card-radius" width="400"> -->
                  <v-img
                    src="../../assets/img/Imagen 3.svg"
                    alt="imagen principal"
                    dark
                  >
                  </v-img>
                  <!-- </v-card> -->
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="d-flex order-0 order-sm-1 order-md-1 order-lg-1"
                >
                  <div class="my-auto">
                    <p class="text-sub-title">Horarios</p>
                    <span class="text-list-regular">
                      Elige la hora en la que quieres recibir tu sesión diaria
                      de aprendizaje.
                    </span>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-hover v-slot="{ hover }">
            <v-btn
              @click="$router.push({ name: 'Register' })"
              :color="hover ? '#373737' : '#061F42'"
              rounded
              class="white--text text-lowercase"
              x-large
              ><span class="text-capitalize white--text mr-2"
                >Experimenta
              </span>
              hoy</v-btn
            >
          </v-hover>
        </v-col>
      </v-row>
      <section id="price">
        <v-row class="mt-15">
          <v-col cols="12" class="mt-15 ml-5">
            <p class="text-start text-regular">Inversión</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, index) in plan"
            :key="index"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card class="shadow-4">
              <div class="px-5 py-5">
                <p
                  class="text-sub-title-regular text-center mb-8"
                  style="font-size: 24px; color: #4d4d4e"
                >
                  {{ item.name }}
                </p>
                <p class="font-weight-bold text-center" v-show="index !== 2">
                  $ {{ item.price }}
                </p>
                <p class="text-center" v-html="item.content"></p>
                <v-divider class="mb-5"></v-divider>
                <p class="font-weight-bold text-center mt-10 mb-n2">
                  Soporte especializado
                </p>
              </div>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="HandlerPay(item)"
                  dark
                  block
                  x-large
                  :color="hover ? '#CBCBCB' : '#061F42'"
                  class="font-light text-lowercase mt-5"
                >
                  <span
                    class="mr-2 text-capitalize font-weight-medium"
                    :style="!hover ? 'color: #CBCBCB' : 'color: #061F42'"
                    >Elegir</span
                  >
                  <span
                    class="font-weight-medium"
                    :style="!hover ? 'color: #CBCBCB' : 'color: #061F42'"
                  >
                    plan
                  </span>
                </v-btn>
              </v-hover>
            </v-card>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="4">
            <v-card class="shadow-4">
              <div class="px-5 py-5">
                <p class="text-sub-title-regular text-center mb-7 text-center">
                  Corporativo
                </p>
                <p class="font-weight-bold text-center">
                  Contáctanos y busca las mejores píldoras para tu empresa
                </p>
                <p class="text-center">
                  Recibes contenido hasta 7 días a la semana a tu WhatsApp.
                </p>
                <v-divider class="mb-5"></v-divider>
                <p class="font-weight-bold text-center mt-7 mb-3">
                  Soporte especializado
                </p>
              </div>
              <v-hover v-slot="{ hover }">
                <v-btn
                  @click="HandlerLanding"
                  dark
                  block
                  x-large
                  :color="hover ? '#CBCBCB' : '#061F42'"
                  class="font-light text-lowercase mb-0"
                >
                  <span
                    class="font-weight-medium mr-2 text-capitalize"
                    :style="!hover ? 'color: #CBCBCB' : 'color: #061F42'"
                    >Elegir</span
                  >
                  <span
                    class="font-weight-medium"
                    :style="!hover ? 'color: #CBCBCB' : 'color: #061F42'"
                  >
                    plan
                  </span>
                </v-btn>
              </v-hover>
            </v-card>
          </v-col> -->
          <v-col cols="12" class="text-center my-15">
            <v-hover v-slot="{ hover }" open-delay="200">
              <v-btn
                @click="$router.push({ name: 'Register' })"
                :color="hover ? '#E05492' : '#0CAAC8'"
                x-large
                rounded
                class="white--text text-lowercase"
                ><span class="text-capitalize white--text mr-2">Empezar</span>
                ahora</v-btn
              >
            </v-hover>
          </v-col>
        </v-row>
      </section>
    </v-container>
  </v-responsive>
</template>

<script>
import ToolbarCore from "../core/Toolbar";

export default {
  components: {
    "toolbar-core": ToolbarCore,
  },

  data() {
    return {
      plan: [],
    };
  },

  created() {
    this.HandlerGetPlans();
  },

  methods: {
    HandlerLanding() {
      window.location = "https://emp.pilsa.io/";
    },

    async HandlerGetPlans() {
      try {
        const response = await this.$store.dispatch("profile/PLAN_SERVICES");
        this.plan = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async HandlerPay(plan) {
      try {
        const data_encrypt = {
          id: plan.id,
          price: plan.price,
        };

        const encryptData = this.CryptoJS.AES.encrypt(
          JSON.stringify(data_encrypt),
          "My$ncrypt-d4t4-Pl4N-s3cr3t"
        ).toString();

        this.$router.push({ name: "checkout", query: { value: encryptData } });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
.shadow-4 {
  /* -webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
        0 4px 6px -2px rgba(0, 0, 0, 0.05) !important; */
  box-shadow: 0px 3px 1px -2px #00000063, 0px 8px 8px 0px rgb(0 0 0 / 25%),
    0px 8px 8px 0px rgb(0 0 0 / 8%) !important;
  border-radius: 30px !important;
}
</style>
