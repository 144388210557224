<template>
  <section>
    <ValidationObserver ref="obs" v-slot="{ passes }">
      <section class="setter">
        <div class="img-left">
          <p></p>
          <v-img cover src="@/assets/img/asian_woman.png"></v-img>
        </div>
        <div class="div-rigth">
          <section v-if="!forgotData" class="section-rigth">
            <div>
              <v-img src="@/assets/img/logo-pilsa.png"></v-img>
            </div>
            <p class="p-text">
              <span>¡Hola! Ingresa tus datos</span>
              <span>para iniciar sesión</span>
            </p>
            <section class="d-flex flex-column min-w-250">
              <div class="d-flex">
                <!-- <div>
              <p class="p13">País (Prefijo)</p>
              <country-autocomplete @dialog:change="HandlerCountryResponse" />
            </div> -->
                <!-- <div class="ml-5">
              <p class="p13">Whastapp</p>
              <ValidationProvider
                name="contraseña"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  @keyup.enter="passes(HandlerLogin)"
                  type="password"
                  v-model="password"
                  class="mt-md-3 max-width"
                  outlined
                  label="Contraseña"
                  :error-messages="errors"
                ></v-text-field>
              </ValidationProvider>
            </div> -->
              </div>
              <div class="d-flex">
                <div>
                  <p class="p13">País (Prefijo)</p>
                  <country-autocomplete
                    @dialog:change="HandlerCountryResponse"
                  />
                </div>
                <div class="ml-5 w-100">
                  <p class="p13">Whastapp</p>
                  <ValidationProvider
                    name="telefono"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="phone_number"
                      class="mt-3 ml-auto"
                      outlined
                      label="Whastapp"
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
              </div>

              <div class="mt--2">
                <p class="p13">Contraseña</p>
                <ValidationProvider
                  name="contraseña"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    @keyup.enter="passes(HandlerLogin)"
                    type="password"
                    v-model="password"
                    class="mt-md-3"
                    outlined
                    label="Contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>
              <p
                class="text-right p15  mt--2"
                @click.stop="HandlerForgotPassword({})"
              >
                ¿Olvidaste tu contraseña?
              </p>
              <v-btn
                :disabled="isValid"
                depressed
                class="white--text font-light py-5 text-capitalize mt-5"
                rounded
                color="#101820"
                :loading="loading"
                @click="HandlerLogin"
              >
                Iniciar Sesión
              </v-btn>

              <v-btn
                color=""
                rounded
                depressed
                class="py-5 text-capitalize mt-5"
                @click="HandlerRoute('Register')"
              >
                Regístrate
              </v-btn>
            </section>
            <!-- <v-btn
          color="#061F42"
          rounded
          class="white--text font-light"
          @click="HandlerRoute('Register')"
        >
          Regístrate
        </v-btn> -->
          </section>

          <recovery-password
            v-if="forgotData"
            :active="forgotData"
            @dialog:change="HandlerResponse"
          />

          <p class="p-relative">
            Copyright © Pilsa 2023 - Todos los derechos reservados.
          </p>
        </div>
      </section>
    </ValidationObserver>

    <!-- <v-responsive>
      <toolbar-core />
      <v-container>
        <v-row justify="center">
          <v-col cols="12" class="pt-0">
            <v-btn
              @click="HandlerGoBack"
              color="#0CAAC8"
              text
              rounded
              class="text-sub-title text-capitalize mt-5 mt-md-0"
            >
              <v-icon large class="mr-3">mdi-arrow-left-drop-circle</v-icon>
              Volver
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6">
            <ValidationObserver ref="obs" v-slot="{ passes }">
              <v-card class="mb-15 card-custom">
                <div class="py-10 px-10">
                  <v-row>
                    <v-col cols="12">
                      <p class="text-sub-title text-center title-card">
                        Iniciar sesión
                      </p>
                    </v-col>
                    <v-col cols="5" sm="4" md="5" class="pr-0">
                      <country-autocomplete
                        @dialog:change="HandlerCountryResponse"
                      />
                    </v-col>
                    <v-col cols="7" sm="8" md="7" class="pl-0">
                      <ValidationProvider
                        name="telefono"
                        rules="required|numeric"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="phone_number"
                          class="mt-3 ml-auto"
                          solo
                          prepend-inner-icon="mdi-whatsapp"
                          label="Número de Whatsapp"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="12">
                      <ValidationProvider
                        name="contraseña"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          @keyup.enter="passes(HandlerLogin)"
                          type="password"
                          v-model="password"
                          class="mt-md-3"
                          solo
                          prepend-inner-icon="mdi-lock-outline"
                          label="Contraseña"
                          :error-messages="errors"
                        ></v-text-field>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        :disabled="isValid"
                        color="#0CAAC8"
                        rounded
                        class="white--text font-light"
                        :loading="loading"
                        @click="passes(HandlerLogin)"
                      >
                        INICIAR SESIÓN
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        @click.stop="HandlerForgotPassword({})"
                        small
                        text
                        color="#0CAAC8"
                        class="text-capitalize"
                      >
                        ¿Olvidaste tu contraseña?
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="d-flex justify-space-between mt-8">
                      <small class="my-auto">¿No tienes cuenta?</small>
                      <v-btn
                        color="#061F42"
                        rounded
                        class="white--text font-light"
                        @click="HandlerRoute('Register')"
                      >
                        Regístrate
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-card>
            </ValidationObserver>
          </v-col>
        </v-row>
      </v-container>
      <v-snackbar top class="mt-10" timeout="10000" v-model="snackbar">
        {{ text }}

        <template v-slot:action="{ attrs }">
          <v-btn color="#EA5899" text v-bind="attrs" @click="snackbar = false">
            cerrar
          </v-btn>
        </template>
      </v-snackbar>
      <recovery-password
        v-if="forgotData"
        :active="forgotData"
        @dialog:change="HandlerResponse"
      />
    </v-responsive> -->
  </section>
</template>

<script>
import Notification from "../Utils/notification";
// import ToolbarCore from "../core/Toolbar";
import RecoveryPasword from "../AutenticationCode/RecoveryPassword";
import CountryAutocomplete from "@/components/Utils/country_autocomplete";

export default {
  components: {
    // "toolbar-core": ToolbarCore,
    "recovery-password": RecoveryPasword,
    "country-autocomplete": CountryAutocomplete,
  },

  data: () => ({
    country_id: {},
    phone_number: "",
    password: "",
    loading: false,
    active: false,
    forgotData: false,
    phone_code: "",
    isValid: false,
    snackbar: false,
    text: "",
    data_ref: "",
  }),

  created() {
    if (this.$route.query.data_ref != undefined) {
      this.HandlerLoginBack(this.$route.query.data_ref);
    }
  },

  watch: {
    phone_number(code) {
      if (code.length == this.phone_code.length - 1) {
        if (this.phone_code == `+${code}`) {
          this.isValid = true;
          this.$snotify.warning(
            "El codigo del pais ya ha sido seleccionado",
            "Alerta!"
          );
        } else {
          this.isValid = false;
        }
      }
    },
  },

  methods: {
    async HandlerLogin() {
      try {
        this.loading = true;
        const request = {
          country_id: this.country_id.id,
          phone_number: this.phone_number,
          password: this.password,
        };

        await this.$store.dispatch("auth/LOGIN", request);

        this.$snotify.success("Un gusto tenerte de vuelta", "¡Bienvenido!");
        this.$router.push({ name: "profile" });
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else if (error.response.status == 401) {
          this.$snotify.error(error.response.data.message, "Error!");
        } else if (error.response.status == 505) {
          this.snackbar = true;
          this.text = error.response.data.message;
        }
      } finally {
        this.loading = false;
      }
    },

    async HandlerLoginBack(id) {
      try {
        this.loading = true;
        const request = {
          data_ref: id,
        };

        await this.$store.dispatch("auth/LOGIN_BACK", request);

        this.$snotify.success("Un gusto tenerte de vuelta", "¡Bienvenido!");
        this.$router.push({ name: "profile" });
      } catch (error) {
        if (error.response.status == 422) {
          let e = error.response.data.error;
          let showNotification = new Notification(e);
          showNotification.notification;
        } else if (error.response.status == 401) {
          this.$snotify.error(error.response.data.message, "Error!");
        } else if (error.response.status == 505) {
          this.snackbar = true;
          this.text = error.response.data.message;
        }
      } finally {
        this.loading = false;
      }
    },

    HandlerGoBack() {
      this.$router.go(-1);
    },

    HandlerRoute(name) {
      this.$router.push({ name: name });
    },

    HandlerForgotPassword() {
      this.forgotData = true;
    },

    HandlerResponse() {
      this.forgotData = false;
    },

    HandlerCountryResponse(val) {
      this.phone_code = val.phone_code;
      this.country_id = val;
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .img-left {
    display: none !important;
  }
  .setter {
    width: 100% !important;
    /* width: 100vw !important; */
    /* background-color: red !important; */
  }
  .div-rigth {
    width: 100% !important;
    padding: 0 1rem !important;
  }
}

.w-100 {
  width: 100%;
}
.p-relative {
  color: #949494;
  font: normal normal 300 13px/20px Poppins;
  position: absolute;
  bottom: 0;
}
.p13 {
  font-size: 13px;
}
.p15 {
  font: normal normal normal 15px/21px Poppins;
  font-size: 15px;
}
.mt--2 {
  margin-top: -10px;
}
.section-rigth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.p-text {
  margin-top: 3rem;
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.setter {
  margin: 0px;
  padding: 0px;
  display: flex;
  height: 100vh;
  justify-content: space-between;
}
.img-left {
  width: 50vw;
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.div-rigth {
  position: relative;
  width: 50vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.d-flex {
  display: flex;
}
.max-width {
  max-width: 250px;
}
</style>
