<template>
  <div class="div-rigth">
    <p class="d-flex flex-column p-text">
      <span>Casi terminamos,falta</span>
      <span>personalizar tu experiencia</span>
    </p>
    <div class="w-75">
      <p class="p13">¿Qué quieres aprender?</p>
      <v-select
        :items="modules"
        item-text="name"
        item-value="id"
        outlined
        v-model="module_id"
      ></v-select>
    </div>
    <div class="w-75">
      <p class="p13">¿Qué sabes del tema?</p>
      <v-select
        disabled
        :items="levels"
        v-model="level_id"
        item-value="name"
        item-text="name_false"
        class="mt-3"
        outlined
      ></v-select>
    </div>
    <div class="w-75">
      <p class="p13">¿A qué hora puedes separar 15 minutos?</p>
      <v-select
        @click="menu2 = true"
        :items="levels"
        v-model="level_id"
        item-value="name"
        item-text="name_false"
        append-icon="mdi-clock-time-two-outline"
        class="mt-3"
        outlined
        >tik</v-select
      >
    </div>
    <v-dialog v-model="menu2">
      <v-time-picker
        color="#0DB2D2"
        ampm-in-title
        format="ampm"
        v-model="time"
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="menu2 = false">
          Cancelar
        </v-btn>
        <v-btn text color="primary" @click="SaveTime(time)">
          Aceptar
        </v-btn>
      </v-time-picker>
    </v-dialog>

    <v-btn
      depressed
      class="white--text font-light py-5 text-capitalize mt-10 w-75"
      rounded
      color="#101820"
      :loading="loading"
      @click="HandlerRegister"
    >
      Continuar
    </v-btn>

    <p class="p-relative">
      Copyright © Pilsa 2023 - Todos los derechos reservados.
    </p>
    <!-- <v-btn
      :loading="loading"
      @click="HandlerRegister"
      color="#0CAAC8"
      rounded
      class="white--text font-light"
      >Guardar</v-btn -->
    <!-- <v-col cols="12" sm="6" md="6">
          <small>¿Qué quieres aprender?</small>
          <v-btn
            :items="modules"
            item-text="name"
            item-value="id"
            class="mt-3"
            solo
            v-model="module_id"
          ></v-select>
        </v-col> -->
    <!-- <v-col cols="12" sm="6" md="6">
          <small>¿Qué sabes del tema?</small>
          <v-select
            disabled
            :items="levels"
            v-model="level_id"
            item-value="name"
            item-text="name_false"
            class="mt-3"
            solo
          ></v-select>
        </v-col> -->
    <v-col cols="12" sm="6" md="6" class="d-none">
      <small>Zona horaria (GMT)</small>
      <v-select
        :items="countries_gtm"
        :item-value="(item) => item"
        item-text="name"
        v-model="country_gtm_default"
        class="mt-3"
        solo
      ></v-select>
      <!-- <v-select
            :items="countries_gtm"
            item-value="gtm"
            item-text="gtm"
            v-model="gtm"
            solo
            dense
          ></v-select> -->
    </v-col>
    <!-- <v-col cols="12" sm="12" md="12">
          <small>¿A qué hora puedes separar 15 minutos?</small>
          <v-card class="mt-3">
            <div class="d-flex">
              <v-menu
                :close-on-click="false"
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="mt-2 mb-1" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-alarm</v-icon>
                  </v-btn>
                </template>
                <v-time-picker
                  color="#0DB2D2"
                  ampm-in-title
                  format="ampm"
                  v-if="menu2"
                  v-model="time"
                  full-width
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu2 = false">
                    Cancelar
                  </v-btn>
                  <v-btn text color="primary" @click="SaveTime(time)">
                    Aceptar
                  </v-btn>
                </v-time-picker>
              </v-menu>
              <div class="mt-4">
                {{ time2 }}
              </div>
            </div>
          </v-card>
        </v-col> -->
  </div>
</template>

<script>
//import moment from "moment";
import moment from "moment-timezone";
import Notification from "../Utils/notification";

export default {
  props: {
    registerData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      time: "00:00",
      time2: null,
      menu2: false,
      modal2: false,
      items: ["Básico", "Intermedio", "Avanzado"],
      modules: [],
      module_id: "",
      levels: [],
      level_id: "",
      loading: false,
      valueTime: "",
      gtm: "+00:00",
      countries_gtm: [],
      country_gtm_default: "",
    };
  },
  filters: {
    nameTilde(value) {
      return value == "basico" ? "básico" : value;
    },
  },
  created() {
    this.HandlerGetModule();
    this.HandlerGmtCountry();
  },
  watch: {
    module_id(module_id) {
      this.HandlerListLevels(module_id);
    },
  },
  methods: {
    async HandlerGetModule() {
      try {
        const response = await this.$store.dispatch("auth/MODULE");
        this.modules = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async HandlerGmtCountry() {
      try {
        this.loadinSelect = true;
        const response = await this.$store.dispatch("auth/GMT_COUNTRIES");
        this.countries_gtm = response.data.data;
        this.country_gtm_default = this.countries_gtm[0];
      } catch (error) {
        console.log("error", error.response);
        this.$snotify.error(
          "Hemos tenido un error obteniendo la informacion de los paises gmt",
          "¡Error!"
        );
      } finally {
        this.loadinSelect = false;
      }
    },
    SaveTime(time) {
      this.time = time;
      this.time2 = moment(time, "H:m").format("hh:mm A");
      this.$refs.menu.save(time);
    },
    async HandlerListLevels(module_id) {
      try {
        const request = {
          module_id: module_id,
        };
        const response = await this.$store.dispatch(
          "profile/LIST_LEVELS",
          request
        );

        const map = response.data.data.map((index) => {
          if (index == "basico") {
            return {
              name: index,
              name_false: index == "basico" ? "básico" : index,
            };
          } else {
            return null;
          }
        });
        if (map[0] != null) {
          this.levels = map;
          this.level_id = "basico";
        } else {
          this.levels = [];
          this.level_id = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    async HandlerRegister() {
      try {
        const gtm_name = moment.tz.guess();
        const gtm = moment.tz(gtm_name).format("Z");

        this.loading = true;
        const request = {
          ...this.registerData,
          module_id: this.module_id,
          level: this.level_id,
          time: this.time,
          gtm_name,
          gtm,
        };

        await this.$store.dispatch("auth/REGISTER", request);
        this.$snotify.success("Su registro ha sido exitoso", "¡Exitos!");
        window.dataLayer.push({ event: "registro" });
        this.$router.push({ name: "Login" });
      } catch (error) {
        let e = error.response.data.error;
        let showNotification = new Notification(e);
        showNotification.notification;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.w-75 {
  width: 75%;
}
.div-rigth {
  position: relative;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  flex-wrap: wrap;
  padding: 0 6rem;
}
.bg-red {
  background-color: red;
}
.p-text {
  margin-top: 3rem;
  font-family: "Poppins", serif;
  /* font-size: 28px; */
  font-weight: 900;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-align: center;
  font: normal normal 600 28px/40px Poppins;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.p13 {
  font-size: 13px;
}

.p-relative {
  color: #949494;
  font: normal normal 300 13px/20px Poppins;
  position: absolute;
  bottom: 0;
  /* margin-top: 3rem;
  padding-top: 4rem; */
}
</style>
