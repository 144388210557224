<template>
  <section>
    <ValidationObserver ref="obs" >
      <div class="ct-loading" v-if="loadingCode"></div>
      
      <section class="setter">
        <div class="img-left">
          <p></p>
          <v-img cover src="@/assets/img/asian_woman.png"></v-img>
        </div>
        
        <div class="div-rigth">
          <section class="section-rigth">
            <div>
              <v-img src="@/assets/img/logo-pilsa.png"></v-img>
            </div>
            
            <section class="d-flex flex-column min-w-250">
              <p class="title_recover_password">¡Prepárate para Empezar!</p>
              <p class="subtitle_recover_password">
                Define tu contraseña y prepárate para sumergirte en un viaje de aprendizaje emocionante.
              </p>

              <div class="mt--2">
                <p class="p13">Crear contraseña</p>
                <ValidationProvider
                   name="contraseña"
                  rules="required|confirmed:confirmation"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    type="password"
                    v-model="password"
                    class="mt-md-3"
                    outlined
                    label="Contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>

              <div class="mt--2">
                <p class="p13">Confirmar Contraseña</p>
                <ValidationProvider
                  name="confirmar contraseña"
                  rules="required"
                  vid="confirmation"
                  v-slot="{ errors }"
                >
                  <v-text-field
                    @keyup.enter="passes(HandlerSavePassword)"
                    type="password"
                    v-model="confirm_password"
                    class="mt-md-3"
                    outlined
                    label="Contraseña"
                    :error-messages="errors"
                  ></v-text-field>
                </ValidationProvider>
              </div>

              <v-btn
                :disabled="!isPasswordValid"
                :loading="loadingReset"
                @click="HandlerSavePassword"
                color="#101820"
                rounded
                class="white--text font-light py-5 text-capitalize mt-5"
              >
                Guardar
              </v-btn>
            </section>
          </section>

          <p class="p-relative">
            Copyright © Pilsa 2023 - Todos los derechos reservados.
          </p>
        </div>
      </section>
    </ValidationObserver>
  </section>
</template>

<script>
export default {
  data() {
    return {
      isValid: false,
      token: "",
      isNotAccept: true,
      loadingRecovery: false,
      phone_number: "",
      loadingCode: false,
      countGetCode: 0,
      intervalId: null,
      loadingReset: false,
      countries: [],
      country_id: "",
      phone: "",
      canChange: false,
      code_recovery: "",
      canReset: false,
      password: "",
      confirm_password: "",
    };
  },
  computed: {
    isPasswordValid() {
      return this.password !== '' && this.password === this.confirm_password;
    }
  },
  watch: {},
  created() {
   
  },

  async mounted() {
    this.token = this.$route.query.token;
    await this.HandlerConfrimCode(this.token);
  },
  methods: {
    close() {
      this.$emit("dialog:change", false);
    },
    async HandlerConfrimCode(code) {
      try {
        this.loadingCode = true;
        const response = await this.$store.dispatch("auth/CONFIRM_CODE", {
          code
        });
        this.phone_number =  response.data.data.whatsapp_ccounts.phone_number;
        this.country_id = response.data.data.whatsapp_ccounts.country_id;
      } catch (error) {
        console.log("error", error.response);
        this.$snotify.error("Ha ocurrido un error con tu solicitud", "¡Error!");
        this.$router.push({ name: 'Login' });
      } finally {
        this.loadingCode = false;
      }
    },
    async HandlerSavePassword() {
      try {
        this.loadingReset = true;
        const request = {
          country_id: this.country_id,
          phone_number: this.phone_number,
          password: this.password,
          password_confirmation: this.confirm_password,
          token: this.token,
        };

          console.log('requestReset',request);


        const responseReset = await this.$store.dispatch("auth/RESET_PASSWORD", request);

        console.log('responseReset',responseReset);
 
        const requestAuth = {
          country_id: this.country_id,
          phone_number: this.phone_number,
          password: this.password,
        };

        console.log('requestAuth',requestAuth);

        await this.$store.dispatch("auth/LOGIN", requestAuth);
        this.$snotify.success("Su contraseña ha sido guardada", "¡Bienvenido!");
        this.$router.push({ name: "profile" });
        
      } catch (error) {
        console.log(error.response);
        this.$snotify.error("Error al defnir la contraseña", "¡Error!");
      } finally {
        this.loadingReset = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .img-left {
    display: none !important;
  }
  .setter {
    width: 100% !important;
    /* width: 100vw !important; */
    /* background-color: red !important; */
  }
  .div-rigth {
    width: 100% !important;
    padding: 0 1rem !important;
  }
}

.w-100 {
  width: 100%;
}
.p-relative {
  color: #949494;
  font: normal normal 300 13px/20px Poppins;
  position: absolute;
  bottom: 0;
}
.p13 {
  font-size: 13px;
}
.p15 {
  font: normal normal normal 15px/21px Poppins;
  font-size: 15px;
}
.mt--2 {
  margin-top: -10px;
}
.section-rigth {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.p-text {
  margin-top: 3rem;
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.setter {
  margin: 0px;
  padding: 0px;
  display: flex;
  height: 100vh;
  justify-content: space-between;
}
.img-left {
  width: 50vw;
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.div-rigth {
  position: relative;
  width: 50vw;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.d-flex {
  display: flex;
}
.max-width {
  max-width: 250px;
}

.subtitle_recover_password {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
  margin-bottom: 4rem !important;
}
.title_recover_password {
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.recoverPasswordContainer_putCode2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.classBtnReqCode {
  color: white;
  border-radius: 32px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.classConYouReq_text {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #0076ff;
}
.recoverPasswordContainer_putCode {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.closeWrelative {
  position: absolute;
  top: 0;
  margin: 3rem;
  right: 0;
}
.classContainerBtn {
  margin-bottom: 10rem;
}
.recoverPasswordContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subtitle_recover_password {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
  margin-bottom: 4rem !important;
}
.title_recover_password {
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.custom-color {
  color: #929292;
}

.ct-loading{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: #ffffff91;
}

</style>
